/**
 * Please notify us when you have significant changes in your system.
 * integration@fitanalytics.com
 **/

window._fitAnalyticsReportPurchase = () => {
  /**
   * basic shop context and configuration
   **/
  const shop = (window._fitAnalyticsReportPurchase.shop = {
    prefix: 'puma_na-',
    timers: {}
  });

  const lookup = (obj, path, defVal) => {
    obj = obj && obj instanceof Object ? obj : {};
    defVal == null && (defVal = null);
    path === '.' && (path = '');
    const keys =
      Object.prototype.toString.apply(path) === '[object Array]' ? path : path.split('.');
    for (let i = 0, n = keys.length; i < n; i++) {
      var v = obj[keys[i]];
      if (v != null) {
        obj = v;
      } else {
        return defVal;
      }
    }
    return v;
  };

  shop.getData = (path, def = '') => {
    return lookup(window, 'fitAnalytics.' + path, def);
  };

  shop.getSize = (index) => {
    const sizesEls = document.body.querySelectorAll('[data-test-id="size"]'),
      sizeEl = (sizesEls && sizesEls[index]) || {};
    return sizeEl.innerText || '';
  };

  // normalize page's purchase data
  shop.getPurchase = function () {
    const purchase = {
      orderId: this.getData('orderId'),
      language: this.getData('shopLanguage'),
      country: this.getData('shopCountry'),
      currency: this.getData('currency'),
      userId: this.getData('userId'),
      products: this.getData('products', [])
    };

    return purchase;
  };

  /**
   * send the purchase to the backend
   **/
  shop.sendPurchase = function () {
    const purchase = this.getPurchase();

    for (let i = 0, l = purchase.products.length; i < l; ++i) {
      const product = purchase.products[i];

      const response = {
        shopSessionId: lookup(window, 'fitAnalytics.sessionId'),
        userId: '',
        orderId: purchase.orderId,
        currency: purchase.currency,
        language: purchase.language,
        shopCountry: purchase.country?.toUpperCase(),
        price: product.price || '',
        quantity: product.quantity || '',
        purchasedSize: shop.getSize(i).trim(),
        ean: `0${product.shopArticleCode}` || '',
        productSerial: (product.productId && shop.prefix + product.productId) || ''
      };

      window._sendPurchaseInformation(response);
      // console.log(response);
    }
  };

  /**
   * loading
   **/
  // poll till we can send the purchase to the backend
  shop.timers.onPageLoaded = setInterval(() => {
    const purchase = shop.getPurchase();

    if (!purchase.products || !purchase.products.length) {
      return;
    } else {
      clearInterval(shop.timers.onPageLoaded);
    }

    shop.sendPurchase();
  }, 50);
};
(() => {
  const $script = document.createElement('script');
  $script.setAttribute('src', '//widget.fitanalytics.com/report_purchase.js');
  $script.setAttribute('type', 'text/javascript');
  $script.setAttribute('async', 'true');
  document.body.appendChild($script);
})();
